import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next-sanity@9.5.0_@sanity+client@6.22.1_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.59._kfq7oyh455xegk5chc2i7ruzfu/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Singleton/ContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoProvider"] */ "/opt/build/repo/src/components/VideoContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/opt/build/repo/src/components/VideoPlayer.tsx");
